<template>
  <div>
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">Create Customer Application</h4>
      </CCol>
      <CCol sm="8"> </CCol>
    </CRow>
    <CRow>
      <CCol sm="7">
        <CCard>
          <CRow>
            <CCol sm="12">
              <ul class="nav nav-tabs" id="CAppTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    :class="activeTab === 1 ? 'active' : ''"
                    id="home-tab"
                    data-toggle="tab"
                    href="javascript:void(0)"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Primary Information</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    :class="activeTab === 2 ? 'active' : ''"
                    id="profile-tab"
                    data-toggle="tab"
                    href="javascript:void(0)"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Address Information</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    :class="activeTab === 3 ? 'active' : ''"
                    id="rFee"
                    data-toggle="tab"
                    href="javascript:void(0)"
                    role="tab"
                    aria-controls="rFee"
                    aria-selected="false"
                    >Connection & Related Fees</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    :class="activeTab === 4 ? 'active' : ''"
                    id="contact-tab"
                    data-toggle="tab"
                    href="javascript:void(0)"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                    >Attachments</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade"
                  :class="activeTab === 1 ? 'show active' : ''"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <PrimaryInfo
                    v-if="activeTab === 1"
                    :application="customer_application"
                    @formSubmitted="handleFormSubmit"
                  />
                </div>
                <div
                  class="tab-pane fade"
                  :class="activeTab === 2 ? 'show active' : ''"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <AddressInfo
                    v-if="activeTab === 2"
                    :application="customer_application"
                    @formSubmitted="handleFormSubmit"
                  />
                </div>
                <div
                  class="tab-pane fade"
                  :class="activeTab === 3 ? 'show active' : ''"
                  id="rFee"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <RelatedFees
                    v-if="activeTab === 3"
                    :application="customer_application"
                    @formSubmitted="handleFormSubmit"
                  />
                </div>
                <div
                  class="tab-pane fade"
                  :class="activeTab === 4 ? 'show active' : ''"
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <Attachments
                    v-if="activeTab === 4"
                    :application="customer_application"
                    @formSubmitted="handleFormSubmit"
                  />
                </div>
              </div>
            </CCol>
          </CRow>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const PrimaryInfo = () =>
  import(/* webpackChunkName: "PrimaryInfo" */ "./tabs/PrimaryInfo");
const AddressInfo = () =>
  import(/* webpackChunkName: "AddressInfo" */ "./tabs/AddressInfo");
  const RelatedFees = () =>
  import(/* webpackChunkName: "AddressInfo" */ "./tabs/RelatedFees");
const Attachments = () =>
  import(/* webpackChunkName: "Attachments" */ "./tabs/Attachments");

export default {
  name: "CreateCustomerAppComp",
  components: {
    PrimaryInfo,
    AddressInfo,
    RelatedFees,
    Attachments,
  },
  data() {
    return {
      activeTab: 1,
      customer_application: {
        account_number: "",
        category: "Domestic",
        connection_type: "0.75 INCH",
        salutation: "",
        first_name: "",
        last_name: "",
        father_name:'',
        mother_name:'',
        spouse_name:'',
        connection_duration:'Permanent',
        has_previous_connection:false,
        related_fees: [],
        total_fees: 0,
        email: "",
        mobile_number: "",
        address: "",
        area: "",
        city: "",
        division: "",
        country: ""
      },
    };
  },
  methods: {
    handleFormSubmit({ data, tab }) {
      this.customer_application = data;
      this.activeTab = tab;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  border: none;
  padding: 40px 30px;
  color: #000;
  label {
    font-weight: 600;
  }
}
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
}
#CAppTab {
  .nav-item {
    .nav-link {
      color: #000 !important;
      font-weight: 600;
    }
  }
}
</style>
